import { useEffect } from "react";
import { useApp } from "../hooks/useApp";
import { useData } from "../hooks/useData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Stack, TextField, Autocomplete } from "@mui/material";
import { options } from "../types/Context";
import { setItem, getItem } from "../utils/LocalStorageManager";

interface companyBox {
  company: options[];
  subCompany?: options[];
}
const schema_options = {
  id: Yup.string(),
  description: Yup.string(),
};

const CompaniesAndSubCompanySelect = () => {
  const { handleFetchRiskLevels } = useApp();
  const {
    companyData,
    handleFetchDataCompany,
    subCompanyData,
    setSubCompanyData,
    handleFetchDataSubCompany,
    handleLocalCompaniesAndSubcompanies,
    companyIndexDB,
  } = useData();

  useEffect(() => {
    handleLocalCompaniesAndSubcompanies &&
      handleLocalCompaniesAndSubcompanies();
  }, []);

  const defaultValues: companyBox = {
    company: companyIndexDB?.company ?? [],
    subCompany: companyIndexDB?.subCompany ?? [],
  };

  const validation = Yup.object().shape({
    company: Yup.object().nullable().shape(schema_options).typeError(""),
    subCompany: Yup.object().nullable().shape(schema_options).typeError(""),
  });
  const {
    formState: { errors },
    control,
    resetField,
    watch,
    reset,
  } = useForm<companyBox>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  let loadSubcompany;

  useEffect(() => {
    if (companyIndexDB !== null) {
      reset(defaultValues);
    }
  }, [companyIndexDB]);

  const watchCompany: any = watch("company");
  const watchSubCompany: any = watch("subCompany");

  useEffect(() => {
    handleFetchDataCompany && handleFetchDataCompany(0, "");
  }, []);

  const setCompanyLocalAndIndexDB = async () => {
    await setItem("setLocalCompany", watchCompany);
    await getItemIndexDBCompany();
  };

  const setSubCompanyLocalAndIndexDB = async () => {
    //console.log(watchSubCompany);
    await setItem("setLocalSubCompany", watchSubCompany);
    await getItemIndexDBCompany();
  };

  useEffect(() => {
    const getSubCompanies = async () => {
      await setCompanyLocalAndIndexDB();
      loadSubcompany = await handleFetchDataSubCompany(0, watchCompany?.id);
      //console.log(loadSubcompany);
      await setItem("setLocalSubCompany", null);
    };

    if (watchCompany?.id) {
      const loadSubCompanies = async () => await getSubCompanies();
      loadSubCompanies();
      handleFetchRiskLevels &&
        handleFetchRiskLevels(
          companyIndexDB?.company?.id,
          companyIndexDB?.subCompany?.id
        );
    } else {
      setSubCompanyData && setSubCompanyData(null);
      resetField("subCompany", { defaultValue: [] });
      removeCompanyIndexDB();
    }
  }, [watchCompany]);

  const removeCompanyIndexDB = async () => {
    await setItem("setLocalCompany", null);
    await setItem("setLocalSubCompany", null);
    await setCompanyLocalAndIndexDB();
  };

  useEffect(() => {
    const setSubCompanyIDB = async () => await setSubCompanyLocalAndIndexDB();
    if (watchSubCompany?.id) {
      //setSubCompanyLocalAndIndexDB();
      setSubCompanyIDB();
      handleFetchRiskLevels &&
        handleFetchRiskLevels(
          companyIndexDB?.company?.id,
          companyIndexDB?.subCompany?.id
        );
    } else {
      //setSubCompanyLocalAndIndexDB();
      setSubCompanyIDB();
      setSubCompanyData && setSubCompanyData(null);
    }
  }, [watchSubCompany]);

  const getItemIndexDBCompany = async () => {
    let localCompany: any = await getItem("setLocalCompany");
    let localSubCompany: any = await getItem("setLocalSubCompany");

    if (
      localCompany?.id !== companyIndexDB?.company?.id ||
      localSubCompany?.id !== companyIndexDB?.subCompany?.id
    ) {
      handleLocalCompaniesAndSubcompanies &&
        handleLocalCompaniesAndSubcompanies();
    }
  };

  return (
    <Stack
      direction={{
        xs: "column",
        sm: "row",
      }}
      spacing={4}
      alignItems={"center"}
      sx={{
        width: {
          xs: "100%",
          sm: "400px",
        },
        padding: {
          xs: "0 15px",
          sm: "0",
        },
      }}
    >
      <Controller
        control={control}
        name="company"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            fullWidth
            onChange={(event, newValue: any) => {
              onChange(newValue);
              //resetField("subCompany", { defaultValue: [] });
            }}
            options={companyData !== null ? companyData : []}
            getOptionLabel={(option: any) => option.description || ""}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            //@ts-ignore
            value={value}
            sx={{
              "& .MuiInputLabel-shrink": {
                transform: "translate(0, 6px) scale(0.7)",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Compañía"
                variant="standard"
                error={!!errors.company}
                helperText={errors.company && errors.company.message}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name="subCompany"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            fullWidth
            onChange={(event, newValue: any) => {
              onChange(newValue);
            }}
            options={subCompanyData !== null ? subCompanyData : []}
            getOptionLabel={(option: any) => option.description || ""}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            //@ts-ignore
            value={value}
            disabled={subCompanyData === null || subCompanyData?.length < 0}
            sx={{
              "& .MuiInputLabel-shrink": {
                transform: "translate(0, 6px) scale(0.7)",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Sub compañías"
                variant="standard"
                disabled={subCompanyData === null}
                error={!!errors.subCompany}
                helperText={errors.subCompany && errors.subCompany.message}
              />
            )}
          />
        )}
      />
    </Stack>
  );
};

export default CompaniesAndSubCompanySelect;
