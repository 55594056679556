import { properties } from "./Properties_es";

/* eslint-disable */
export const validateFieldStyle = (applyStyle, fieldError) => {
  let style = "";
  if (applyStyle) {
    style = "is-valid";
    if (fieldError) {
      style = "is-invalid";
    }
  }
  return style;
};

export const isValidEmail = (email) => {
  let re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const minToHours = (min) => {
  if (!min || min <= 0) {
    return properties.com_mapprisk_label_certificate_generationtime_desc_format(
      0,
      0
    );
  }
  var hours = min / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return properties.com_mapprisk_label_certificate_generationtime_desc_format(
    rhours,
    rminutes
  );
};

/**
 * Función que convierte un número de minutos
 * a su representación legible en horas, días, semanas,
 * meses y años. Por ejemplo:
 *
 * 72080 minuto(s) son 1 mes, 2 semanas, 6 días, 1 hora, 20 minutos
 *
 */
export const minToTime = (minutos) => {
  const leyenda = (numero, palabra, plural) =>
    numero === 0 || numero > 1
      ? `${numero} ${palabra}${plural || "s"}`
      : `${numero} ${palabra}`;
  const MINUTOS_POR_HORA = 60,
    HORAS_POR_DIA = 24,
    DIAS_POR_SEMANA = 7,
    DIAS_POR_MES = 30,
    MESES_POR_ANIO = 12;
  if (minutos < MINUTOS_POR_HORA) return leyenda(minutos, "minuto");
  let horas = Math.floor(minutos / MINUTOS_POR_HORA),
    minutosSobrantes = minutos % MINUTOS_POR_HORA;
  if (horas < HORAS_POR_DIA)
    return (
      leyenda(horas, "hora") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let dias = Math.floor(horas / HORAS_POR_DIA);
  minutosSobrantes = minutos % (MINUTOS_POR_HORA * HORAS_POR_DIA);
  if (dias < DIAS_POR_SEMANA)
    return (
      leyenda(dias, "día") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let semanas = Math.floor(horas / (HORAS_POR_DIA * DIAS_POR_SEMANA));
  minutosSobrantes =
    minutos % (MINUTOS_POR_HORA * HORAS_POR_DIA * DIAS_POR_SEMANA);
  if (dias < DIAS_POR_MES)
    return (
      leyenda(semanas, "semana") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let meses = Math.floor(horas / (HORAS_POR_DIA * DIAS_POR_MES));
  minutosSobrantes =
    minutos % (MINUTOS_POR_HORA * HORAS_POR_DIA * DIAS_POR_MES);
  if (meses < MESES_POR_ANIO)
    return (
      leyenda(meses, "mes", "es") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let anios = Math.floor(
    horas / (HORAS_POR_DIA * DIAS_POR_MES * MESES_POR_ANIO)
  );
  minutosSobrantes =
    minutos %
    (MINUTOS_POR_HORA * HORAS_POR_DIA * DIAS_POR_MES * MESES_POR_ANIO);
  return (
    leyenda(anios, "año") +
    (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
  );
};

export const convertTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date) => {
  return [
    convertTo2Digits(date.getDate()),
    convertTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

export const isColorLight = (color) => {
  if (!color) {
    console.log("no color");
    return true;
  }
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};
