import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
} from "../components/svgicons/SvgIcons";
import { properties } from "../utils/Properties_es";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";

import FancyTablePagination from "../components/utils/FancyTablePagination";
import { useApp } from "../hooks/useApp";
import { useData } from "../hooks/useData";
import AddOrUpdateCompanyModal from "../components/utils/AddOrUpdateCompanyModal";

import DeleteModal from "../components/utils/DeleteModal";
import { useForm } from "react-hook-form";

import {
  getRequest,
  createRequest,
  updateRequest,
  findByIdRequest,
  deleteRequest,
} from "../services/company/CompanyService";

import {
  getRequestSubcompany,
  createRequestSubcompany,
  updateRequestSubcompany,
  findByIdRequestSubcompany,
  deleteRequestSubcompany,
} from "../services/subCompany/SubCompanyServices";

import {
  uploadDocuments,
  deleteDocuments,
  findDocuments,
} from "../services/company/CompanyService";

import ResourceAccess from "../components/security/ResourceAccess";

interface AddOrUpdateSubCompanies {
  id?: number;
  description: string;
  numberInquiries?: number;
  companyId?: {
    id: number;
  };
  //companyId?: number;
  file?: File | null;
  oldFile?: File | null;
}

let contadorSubCompanies: number = 0;
let totalSubCompanies: number = 0;

const SettingCompanies = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const { handleFetchDataCompany } = useData();
  const theme = useTheme();
  const navigate = useNavigate();
  const [companyDataTable, setCompanyDataTable] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);

  const initForm = {
    id: 0,
    description: "",
    subCompany: "",
    numberInquiries: "",
    addSubCompanies: [],
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
      console.log(companyDataTable);
    };
    dataInit();
  }, []);

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleFetchData = async (currentPage: number, filter?: string) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);
      let data: any = await getRequest(currentPage, filter);
      //console.log(data.content)
      if (data) {
        setCompanyDataTable(data);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    //console.log(data);
    try {
      //crear companias

      const companyDataTable = {
        id: 0,
        description: data.description,
        numberInquiries: data.numberInquiries,
        createdAt: new Date(),
        createdBy: authInfo.username,
        "create-factors": data.addSubCompanies.length !== 0 ? false : true,
        //"create-factors": false,
      };

      let createData = await createRequest({
        ...companyDataTable,
      });
      //@ts-ignore
      const idCompany = createData.id;

      if (!createData) {
        setLoading && setLoading(false);
        //console.log("no se envia");
        return;
      }
      console.log(data.documents);
      if (data.documents !== null && data.addSubCompanies.length === 0) {
        const formData = new FormData();
        formData.append("companyId", idCompany);
        formData.append("idDocumento", "10");
        formData.append("user", authInfo.username);
        formData.append("file", data.documents);
        await uploadDocuments(formData);
      }

      //agregar subcompanias
      if (data.addSubCompanies.length != 0) {
        let dataSubCompanies: AddOrUpdateSubCompanies[] = [];
        for (let i = 0; i < data.addSubCompanies.length; i++) {
          const obj = data.addSubCompanies[i];
          //console.log("obj de subcompany", obj);
          //const newItemStr = `{"id":0,"description":"${obj.description}","numberInquiries":${obj.numberInquiries},"companyId":{"id":${idCompany}} }`;
          const newItemStr = {
            id: obj.id,
            description: obj.description,
            numberInquiries: obj.numberInquiries,
            companyId: {
              id: idCompany,
            },
            file: obj?.file,
            oldFile: obj?.oldFile,
          };
          //console.log("newItemStr sub: ", newItemStr);
          //const newItem = JSON.parse(newItemStr);
          //dataSubCompanies.push(newItem);
          dataSubCompanies.push(newItemStr);
        }
        contadorSubCompanies = 0;
        totalSubCompanies = data.addSubCompanies.length;
        let createData: any = await createRequestSubcompany({
          dataSubCompanies,
        });
        if (createData) {
          //console.log(createData);
          const formData = new FormData();
          formData.append("companyId", idCompany);
          formData.append("subcompanyId", createData[0].id);
          formData.append("idDocumento", "10");
          formData.append("user", authInfo.username);
          formData.append("file", data.documents);
          await uploadDocuments(formData);
        }

        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_mapprisk_label_request_save);

        //call fetch data
        handleFetchDataCompany && handleFetchDataCompany(0, "");
        await handleFetchData(0, "");
      } else {
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_mapprisk_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log("Entra en editaar");
    console.log(data);
    try {
      let updateData = await updateRequest({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_mapprisk_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }

      const idCompany = data.id;

      if (data.documents !== null) {
        let document: any = await handleFetchDocumentByCompany(idCompany);
        if (document) {
          document?.map((d: any) => deleteDocuments(d.id, authInfo.username));
        }

        const formData = new FormData();
        formData.append("companyId", idCompany);
        formData.append("idDocumento", "10");
        formData.append("user", authInfo.username);
        formData.append("file", data.documents);
        await uploadDocuments(formData);
      }

      if (data.addSubCompanies.length != 0) {
        //agragar subcompanias
        let dataSubCompanies: AddOrUpdateSubCompanies[] = [];
        let contRowsAdd = 0;
        for (let i = 0; i < data.addSubCompanies.length; i++) {
          const obj = data.addSubCompanies[i];
          if (obj.id === 0) {
            //const newItemStr = `{"id":0,"description":"${obj.description}","numberInquiries":${obj.numberInquiries},"companyId":{"id":${idCompany}} }`;
            const newItemStr = {
              id: obj.id,
              description: obj.description,
              numberInquiries: obj.numberInquiries,
              companyId: {
                id: idCompany,
              },
              file: obj?.file,
              oldFile: obj?.oldFile,
            };
            //const newItem = JSON.parse(newItemStr);
            //dataSubCompanies.push(newItem);
            dataSubCompanies.push(newItemStr);
            contRowsAdd++;
          }
        }
        //console.log("dataSubCompaniesAdd=",dataSubCompanies);

        if (contRowsAdd != 0) {
          //console.log(dataSubCompanies);
          let createData: any = await createRequestSubcompany({
            dataSubCompanies,
          });
          //console.log(createData);
          if (createData?.length > 0) {
            for (let i = 0; i < createData.length; i++) {
              //console.log(dataSubCompanies[i]);
              //console.log(dataSubCompanies[i].file);
              //Voy aquíiiiiii
              const formData = new FormData();
              formData.append("companyId", createData[i].companyId.id);
              formData.append("subcompanyId", createData[i].id);
              formData.append("idDocumento", "10");
              formData.append("user", authInfo.username); //@ts-ignore
              formData.append("file", dataSubCompanies[i].file);
              await uploadDocuments(formData);
            }
          }
        }

        //console.log(data.addSubCompanies);
        //editar subcompanias
        let dataSubCompaniesUpdate: AddOrUpdateSubCompanies[] = [];
        let contRowsEdit = 0;
        for (let i = 0; i < data.addSubCompanies.length; i++) {
          const obj = data.addSubCompanies[i];
          //console.log(obj);
          if (obj.id != 0) {
            /* const newItemStr = `{"id":${obj.id},"description":"${
              obj.description
            }","numberInquiries":${
              obj.numberInquiries
            },"companyId":{"id":${idCompany}}, "file": ${obj?.file ?? null}}`; */
            const newItemStr = {
              id: obj.id,
              description: obj.description,
              numberInquiries: obj.numberInquiries,
              companyId: {
                id: idCompany,
              },
              file: obj?.file,
              oldFile: obj?.oldFile,
            };
            //const newItem = JSON.parse(newItemStr);
            //dataSubCompaniesUpdate.push(newItem);
            dataSubCompaniesUpdate.push(newItemStr);
            contRowsEdit++;
          }
        }
        //console.log("dataSubCompaniesUpdate=",dataSubCompaniesUpdate);
        contadorSubCompanies = 0;
        totalSubCompanies = contRowsEdit;
        if (contRowsEdit != 0) {
          handleUpdateSubCompanies(dataSubCompaniesUpdate);
        }

        if (contRowsEdit === 0) {
          setLoading && setLoading(false);
          setSuccessMsg &&
            setSuccessMsg(properties.com_mapprisk_label_request_update);
          //call fetch data
          handleFetchDataCompany && handleFetchDataCompany(0, "");
          await handleFetchData(0, "");
        }
      } else {
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_mapprisk_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdateSubCompanies = async (data: any) => {
    //console.log("entra en editar subCompany");
    try {
      if (contadorSubCompanies >= totalSubCompanies) {
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_mapprisk_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
        handleFetchDataCompany && handleFetchDataCompany(0, "");
      } else {
        const item = data[contadorSubCompanies];
        //console.log(item);
        let updateData: any = await updateRequestSubcompany({
          ...item,
        });

        if (item.file !== null) {
          //console.log(item.oldFile.id);
          item.oldFile !== null &&
            item.oldFile?.id &&
            (await deleteDocuments(item?.oldFile?.id, authInfo.username));
          const formData = new FormData();
          formData.append("companyId", item.companyId.id);
          formData.append("subcompanyId", item.id);
          formData.append("idDocumento", "10");
          formData.append("user", authInfo.username);
          formData.append("file", item.file);
          await uploadDocuments(formData);
        }

        contadorSubCompanies++;
        handleUpdateSubCompanies(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };
  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //console.log("handleDelete id="+id);
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let document: any = await handleFetchDocumentByCompany(id);
      if (document) {
        await deleteDocuments(document[0]?.id, authInfo.username);
      }
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg("asd");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_delete);

      //reset page and call fetch data
      handleFetchDataCompany && handleFetchDataCompany(0, "");
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let companyDataTable: any = await findByIdRequest(id);
      //buscar subcompañias
      const filter = companyDataTable?.content[0].id;
      let dataSubcompanies: any = await getRequestSubcompany(0, filter);
      companyDataTable.content[0].addSubCompanies = dataSubcompanies.content;
      setFormData(companyDataTable.content[0]);
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Consultar documentos por company
   */
  const handleFetchDocumentByCompany = async (id: string) => {
    let getDocument: any;
    try {
      getDocument = await findDocuments(id);
    } catch (error: any) {
      console.log(error);
    }
    return getDocument;
  };

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    //console.log("handleOpenModal id=",id);

    if (modalAction === "update") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = companyDataTable.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  return (
    <>
      <FancyPaper pagetitle="Configuración / Compañías">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"COMPANIES:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar compañía
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchDataCompany && handleFetchDataCompany(0, "");
                    handleFetchData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Número de consultas</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyDataTable.content &&
                companyDataTable.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.numberInquiries}
                    </TableCell>
                    <TableCell align="center">
                      <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            companyDataTable?.content?.length > 0
              ? companyDataTable?.content?.length
              : 0
          }
          rowsPerPage={companyDataTable.size}
          page={page}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={companyDataTable.totalElements}
          totalPages={companyDataTable.totalPages}
          numberOfElements={companyDataTable?.numberOfElements ?? 0}
        />
      </FancyPaper>

      {modalData?.modalType === "create" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateCompanyModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}

      {modalData?.modalType === "update" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateCompanyModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}

      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.description}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingCompanies;
