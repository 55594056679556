import {
  useState,
  createContext,
  useMemo,
  useEffect,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { requestTypes } from "../types/Context";
import { setAuthData, setItem } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import { UrlBase } from "../url/Urls";
import { SVSessionValidateRequest } from "../services/security/SecurityService";
import { getRiskLevels } from "../services/riskLevels/RiskLevelsService";
import { ApiRiskLevelResponse } from "../pages/maintenance/RiskLevelsModel";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
};

const AppContext = createContext<requestTypes>(initialState);
const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  const [authenticate, setAuthenticate] = useState(authData != null);
  const [defaultRiskData, setDefaultRiskData] = useState<boolean>(true);
  const [riskData, setRiskData] = useState<ApiRiskLevelResponse | null>(null);
  const navigate = useNavigate();

  const loginProvider = async (loginData: any) => {
    try {
      const sessionCode = loginData.authCode;
      const email = loginData.username;
      if (sessionCode && email) {
        const loginDataValidate = await SVSessionValidateRequest(
          email,
          sessionCode
        );
        //save login data in storage
        const authData = await setAuthData(
          "authMappRiskCompliance",
          loginDataValidate
        );
        if (!authData) {
          setAuthenticate(false);
          return;
        }
        const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token);
        const expiresAt = decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        //let valid = true;
        if (valid) {
          setAuthenticate(valid);
          setAuthInfo(authData);
          navigate("/dashboard");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setAuthenticate(false);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e: any) => {
    e.preventDefault();
    await setAuthData("authMappRiskCompliance", null);
    await setItem("setLocalCompany", null);
    await setItem("setLocalSubCompany", null);
    setAuthenticate(false);
    navigate("/", { replace: true });
  };

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const handleFetchRiskLevels = async (
    companyId: string,
    subcompanyId: string
  ) => {
    setLoading && setLoading(true);
    try {

      if(!companyId) {
        setLoading && setLoading(false);
        return;
      }

      let data = await getRiskLevels(companyId, subcompanyId ?? 0);

      // Validar que cada elemento en `details` tenga al menos 3 elementos
      const isValid = data.every((item) => item.details.length >= 3);

      if (data.length && isValid) {
        setDefaultRiskData(false);
        setRiskData(data);
      } else {
        setDefaultRiskData(true);
        setRiskData(null);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      //console.log(error);
    }
  };

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      defaultRiskData,
      riskData,
      setDefaultRiskData,
      handleFetchRiskLevels,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      defaultRiskData,
      riskData,
    ]
  );

  // Cerrar sesión

  // useEffect(() => {
  //   let time = 300000;
  //   let timeOutTimer = setTimeout(logoutProvider, time);
  //   const checkInactivity = () => {
  //     window.onclick = () => checkInactivity();
  //     window.onkeypress = () => checkInactivity();
  //     window.addEventListener("mousemove", checkInactivity);
  //     clearTimeout(timeOutTimer);
  //     timeOutTimer = setTimeout(logoutProvider, time);
  //   };
  //   checkInactivity();
  // }, []);

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
