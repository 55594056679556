import { privateFetch } from "../../utils/CustomFetch";
import { URL } from "../../utils/UrlConstants";

export const createProfileMatriz = async (data: any) => {

  try {
    const response = await privateFetch.post(URL.REQUEST_PROFILE_MATRIZ_CREATE, {
      ...data,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};



export const getProfileMatriz = async (company: string, subCompany: string, filter: string) => {
  let responseData: any;
  const params = new URLSearchParams([
    ["company", company ?? ""],
    ["subcompany", subCompany ?? ""],
    ["documentNumber", filter],
  ]);

  let keysParams: any = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key: any) => {
    params.delete(key);
  });

  await privateFetch
    .get(URL.REQUEST_PROFILE_MATRIZ_USER(0, "1000"), {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};
